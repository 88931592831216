import { format } from 'date-fns';
import Swiper, { Navigation } from 'swiper';
const checkoutCompleteCB = () => alert('complete');
const triggerEventCheckout = (eventId) => {
  window.EBWidgets.createWidget({
    widgetType: 'checkout',
    eventId: eventId,
    modal: true,
    modalTriggerElementId: `btn-${eventId}`,
    onOrderComplete: checkoutCompleteCB
  });
};
const initEventCheckout = () => {
  let eventId = document
    .getElementById('eventID')
    .getAttribute('data-event-id');
  if (eventId) triggerEventCheckout(eventId);
};

if (document.querySelector('.event-page')) initEventCheckout();

const initCalendar = () => {
  const monthNameEl = document.querySelector('#monthName');
  const currDateKey = format(new Date(), 'MM-dd-yyyy');
  const currDateEl = document.querySelector(`[data-datekey="${currDateKey}"]`);
  if (currDateEl) {
    currDateEl.classList.add('current-day');
    currDateEl.parentElement.classList.add('current-week');
    currDateEl.parentElement.parentElement.classList.add('current-month');
  }

  const swiper = new Swiper('.swiper', {
    autoHeight: true,
    spaceBetween: 0,
    hashNavigation: true,
    modules: [Navigation],
    navigation: {
      prevEl: '#prevMonthBtn',
      nextEl: '#nextMonthBtn'
    }
  });
  const monthsArr = [];
  document.querySelectorAll('.calendar__month').forEach((el) => {
    monthsArr.push(el.getAttribute('data-month'));
  });
  const updateMonthLabel = () => {
    monthNameEl.innerHTML = monthsArr[swiper.realIndex];
  };

  swiper.on('slideChange', updateMonthLabel);
  updateMonthLabel();

  const ticketBtns = document.querySelectorAll('.js-ticket-btn');
  const createdWidgets = [];
  ticketBtns.forEach((btn) => {
    btn.addEventListener('click', () => {
      if (!createdWidgets.includes(btn)) {
        triggerEventCheckout(btn.getAttribute('data-event-id'));
        createdWidgets.push(btn);
      }
    });
  });
};

if (document.querySelector('body.calendar')) initCalendar();
